import React, { useEffect, useState } from "react";
import { CheckIcon, PlusIcon } from "@suns/design-system/icons";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../Command/Command";
import { Button } from "../Button/Button";
import { Separator } from "../Separator/Separator";
import { Text } from "../Text/Text";
import { Badge } from "../Badge/Badge";
import { cn } from "../../utils";

interface FilterOption {
  key: string;
  name: string;
  badgeValue?: string | React.ReactNode;
}

interface FilterProps {
  type: "playerFilter" | "teamFilter" | "tagFilter";
  title?: string;
  value: string[];
  onChange: (keys: string[]) => void;
  options: FilterOption[];
  avoidCollisions?: boolean;
  loading?: boolean;
  onSearchChange?: (value: string) => void;
  isDisabled?: (optionKey: string) => boolean;
}

const Filter = React.forwardRef<HTMLButtonElement, FilterProps>(
  (
    {
      type,
      title,
      value = [],
      onChange,
      options,
      avoidCollisions,
      loading,
      onSearchChange,
      isDisabled,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
      if (!open) {
        onSearchChange?.("");
      }
    }, [open, onSearchChange]);

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button variant="outline" className="my-3 w-full lg:w-auto" ref={ref}>
            <PlusIcon className="mr-2 size-4" />
            <Text size="sm">{loading ? "Loading..." : title}</Text>
            <Badge
              variant="secondary"
              className="ml-2 rounded-md px-2 font-normal lg:hidden"
            >
              {value?.length}
            </Badge>
            {value?.length > 0 && (
              <>
                <Separator
                  orientation="vertical"
                  className="mx-2 h-4 xs:hidden md:hidden lg:flex"
                />
                <div className="hidden space-x-1 lg:flex">
                  {value.length > 3 ? (
                    <Badge
                      variant="secondary"
                      className="rounded-md px-2 font-normal "
                    >
                      {value.length} selected
                    </Badge>
                  ) : (
                    options
                      .filter((option) => value?.includes(option.key))
                      .map((option) => (
                        <Badge
                          variant="secondary"
                          key={option.key}
                          className="rounded-md px-2 font-normal "
                        >
                          {option.name}
                        </Badge>
                      ))
                  )}
                </div>
              </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          avoidCollisions={avoidCollisions}
          className="w-[250px] p-0"
          align="start"
        >
          <Command shouldFilter={type === "tagFilter"}>
            <CommandInput onValueChange={onSearchChange} />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = value?.includes(option.key);
                  const disabled = isDisabled ? isDisabled(option.key) : false;
                  return (
                    <CommandItem
                      disabled={disabled}
                      key={option.key}
                      onSelect={() => {
                        let newValue;
                        if (isSelected) {
                          newValue = value.filter((val) => val !== option.key);
                        } else {
                          newValue = [...value, option.key];
                        }
                        onChange(
                          newValue.filter((val): val is string => val != null)
                        );
                      }}
                    >
                      <div
                        className={cn(
                          "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-black",
                          disabled
                            ? "bg-gray-500 text-secondary-foreground"
                            : isSelected
                              ? "bg-secondary text-secondary-foreground"
                              : "opacity-50 [&_svg]:invisible"
                        )}
                      >
                        <CheckIcon />
                      </div>

                      <span className="mr-auto">{option.name}</span>
                      {option.badgeValue ? (
                        <Badge variant="default">{option.badgeValue}</Badge>
                      ) : null}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              {value?.length > 0 && (
                <>
                  <Separator />
                  <CommandGroup>
                    <CommandItem
                      onSelect={() => onChange([])}
                      className="justify-center text-center"
                    >
                      Clear selections
                    </CommandItem>
                  </CommandGroup>
                </>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);

Filter.displayName = "Filter";

export { Filter };
