import {
  HTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
  forwardRef,
} from "react";
import { cn } from "../../utils";

/**
 * Table
 */

const Table = forwardRef<HTMLTableElement, HTMLAttributes<HTMLTableElement>>(
  ({ className, ...props }, ref) => (
    <div
      className={cn(
        "relative w-full overflow-auto rounded-t-lg text-sm",
        className
      )}
    >
      <table ref={ref} className="w-full caption-bottom" {...props} />
    </div>
  )
);
Table.displayName = "Table";

/**
 * TableHeader
 */

const TableHeader = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn("bg-gray-800 text-white [&_tr]:border-b", className)}
    {...props}
  />
));
TableHeader.displayName = "TableHeader";

/**
 * TableBody
 */

const TableBody = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn("[&_tr:last-child]:border-0", className)}
    {...props}
  />
));
TableBody.displayName = "TableBody";

/**
 * TableFooter
 */

const TableFooter = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      "border-t bg-muted/50 font-medium [&>tr]:last:border-b-0",
      className
    )}
    {...props}
  />
));
TableFooter.displayName = "TableFooter";

/**
 * TableRow
 */

interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
  flex?: boolean;
  justify?: "left" | "center" | "auto";
}

const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ className, flex = false, justify = "auto", children, ...props }, ref) => {
    return (
      <tr
        ref={ref}
        className={cn(
          "border-b data-[state=selected]:bg-muted",
          justify == "left" ? "text-left" : "text-center",
          justify == "auto" && !flex && "first:[&>*]:text-left",
          className
        )}
        {...props}
      >
        {flex ? (
          <TableHead full>
            <div className="flex items-center [&>*]:grow">{children}</div>
          </TableHead>
        ) : (
          children
        )}
      </tr>
    );
  }
);
TableRow.displayName = "TableRow";

/**
 * TableHead
 */

interface TableHeadProps extends ThHTMLAttributes<HTMLTableCellElement> {
  full?: boolean;
}

const TableHead = forwardRef<HTMLTableCellElement, TableHeadProps>(
  ({ className, full = false, colSpan, ...props }, ref) => (
    <th
      ref={ref}
      className={cn(
        "p-3 align-middle font-medium [&:has([role=checkbox])]:pr-0",
        full && "p-0",
        className
      )}
      colSpan={colSpan ?? (full ? 100 : undefined)}
      {...props}
    />
  )
);
TableHead.displayName = "TableHead";

/**
 * TableCell
 */

const TableCell = forwardRef<
  HTMLTableCellElement,
  TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn("p-3 align-middle [&:has([role=checkbox])]:pr-0", className)}
    {...props}
  />
));
TableCell.displayName = "TableCell";

/**
 * TableCaption
 */

const TableCaption = forwardRef<
  HTMLTableCaptionElement,
  HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-muted-foreground", className)}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
};
